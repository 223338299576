import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Utilities
import slugify from "@sindresorhus/slugify"

const ProduitCouleurCard = ({ data }) => {

  return (
    <div key={data.id} className="col-lg-4 col-md-6 mb-3" data-aos="fade-up">
      <Link to={`/produits/couleurs/${slugify(data.nom)}/`}>
        <div className="card card-bordered card-transition shadow">
          <div key={data.id} className="mb-4">
            {data.produit ? 
                data.produit.map((produit, index) => (
                  produit.edges.map((produit, index) => (
                    <GatsbyImage
                      key={index}
                      image={getImage(produit.node.frontmatter.hero_image)}
                      className="card-img-top card-img-h-10"
                      alt={data.nom}
                    />                    
                  ))
                ))
            : null }
          </div>
          <div key={data.id} className="card-body">
            <p className="h4 text-dark">{data.nom}</p>
          </div>
        </div>
      </Link>
     </div>
  )
}

export default ProduitCouleurCard