import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

// Components
import Layout from '../../../components/layout/Layout'
import ProduitCouleurCard from '../../../components/card/ProduitCouleur'

// Couleurs 
const ProduitsCouleursPage = ({ pageContext, data }) => {
  const totalCount  = data.couleurs.group.length
  const pageTitle = `Produits - Couleurs - ${totalCount} couleur${
    totalCount === 1 ? "" : "s"
  }`
  const header = `${totalCount} couleur${
    totalCount === 1 ? "" : "s"
  } de produits`

  return (
    <Layout pageTitle={pageTitle} headerClassPosition="sticky-top">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{header}</h2>
            <ol>
              <li key="accueil">
                <Link to={`/`} className="text-reset">Accueil</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {
              data.couleurs.group.map((couleur, index) => (
                <ProduitCouleurCard key={index} data={couleur}/>
              ))
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

ProduitsCouleursPage.propTypes = {
  data: PropTypes.shape({
    couleurs: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default ProduitsCouleursPage

export const query = graphql`
  query {
    couleurs: allMdx(
      filter: {fileAbsolutePath: {regex: "/produits/"}}
    ) {
      group(field: frontmatter___couleurs) {
        nom: fieldValue
        totalCount
        produit: group(field: frontmatter___title, limit: 1) {
          edges {
            node {
              frontmatter {
                title
                marque
                categorie
                sous_categorie
                card_image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                hero_image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`